exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-1-index-js": () => import("./../../../src/pages/register1/index.js" /* webpackChunkName: "component---src-pages-register-1-index-js" */),
  "component---src-pages-register-1-order-id-js": () => import("./../../../src/pages/register1/[orderId].js" /* webpackChunkName: "component---src-pages-register-1-order-id-js" */),
  "component---src-pages-register-1-register-js": () => import("./../../../src/pages/register1/register.js" /* webpackChunkName: "component---src-pages-register-1-register-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-order-id-js": () => import("./../../../src/pages/register/[orderId].js" /* webpackChunkName: "component---src-pages-register-order-id-js" */),
  "component---src-pages-register-register-js": () => import("./../../../src/pages/register/register.js" /* webpackChunkName: "component---src-pages-register-register-js" */)
}

